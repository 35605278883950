import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../../modal';
import type {
  ColumnErrors,
  FileMapping,
  FilePreview,
  FileUploadMetadata,
  ParsedPrivateFunds,
  Portfolio,
  UploadTypeEnum,
} from 'venn-api';
import { cachedGetFileUploadMetadata, storePrivatesXLS } from 'venn-api';
import { Review, SelectDataType, Upload } from './views';
import { AppendType, DataUploaderMode, DataUploaderView } from './types';
import Loading from './components/Loading';
import {
  fetchCorrectData,
  fetchNewNavsUploadFile,
  fetchParsedFile,
  fetchPersistSeries,
  fetchPrivatesUploadFile,
  fetchUpdateMapping,
  fetchUploadFile,
} from './fetchers';
import { Provider } from './context';
import type { ErrorViewModel } from './views/review/helpers';
import {
  addInvestmentsToPortfolio,
  analyticsService,
  createPortfolioFromFunds,
  logExceptionIntoSentry,
  useHasFF,
} from 'venn-utils';
import MappingNavs from './views/mapping/MappingNavs';
import { UserContext } from '../../contexts';
import { getErrorCounts, getMapping, getSteps, uploadConfig } from './utils';
import { ZIndex } from 'venn-ui-kit';
import { ReviewPrivates } from './views/review/ReviewPrivates';
import { useRecoilValue } from 'recoil';
import { globalUploadAppendTypePrivateFund, uploadAppendTypePrivateFundMap } from 'venn-state';

export interface DataUploaderProps {
  mode: DataUploaderMode;
  setMode: (arg0: DataUploaderMode) => void;
  portfolio?: Portfolio;
  strategyId?: number;
  onCancel: () => void;
  onComplete?: (portfolio: Portfolio, newFunds?: Portfolio[]) => void;
  onCompleteNavigate?: (mode: DataUploaderMode, uploadedFundIds?: string[]) => void;
  /**
   * Custom text to display on the Upload Review screen
   */
  customUploadReviewButtonLabel?: string;
}

interface DataUploaderState {
  loading: boolean;
  error: string | React.ReactNode;
  uploadType: UploadTypeEnum | null;
  step: number;
  metadata?: FileUploadMetadata;
  mapping?: FileMapping;
  preview?: FilePreview;
  errors?: ColumnErrors[];
  parsedPrivateFunds?: ParsedPrivateFunds;
}

const trackColumnErrors = (errors: ColumnErrors[], step: number, mode: DataUploaderMode = DataUploaderMode.Returns) => {
  if (errors.length > 0) {
    const errorCountMap = getErrorCounts(errors);
    const errorTypes: string[] = Object.keys(errorCountMap);
    const errorCounts: number[] = errorTypes.map((type) => errorCountMap[type]);
    analyticsService.uploadStepFailed({
      dataType: getStringMode(mode),
      step,
      errorTypes,
      errorCounts,
    });
  }
};

const getStringMode = (mode: DataUploaderMode) => uploadConfig[mode].dataType;

const ANALYTICS_UPLOAD_TYPE: { [K in UploadTypeEnum]?: string } = {
  FILE: 'file',
  COPY_PASTE: 'copy-paste',
  SAMPLE_FILE: 'samplefile',
};

const DataUploader = ({
  mode,
  setMode,
  portfolio,
  strategyId,
  onCancel,
  onComplete,
  onCompleteNavigate,
  customUploadReviewButtonLabel,
}: DataUploaderProps) => {
  const userHasPrivates = useHasFF('private_analytics');
  const { setUserCompletedUpload } = useContext(UserContext);
  const [state, setState] = useState<DataUploaderState>({
    loading: false,
    error: '',
    step: 0,
    uploadType: null,
  });
  const globalAppendType = useRecoilValue(globalUploadAppendTypePrivateFund);
  const fundAppendTypes = useRecoilValue(uploadAppendTypePrivateFundMap);

  const steps = getSteps(mode);

  useEffect(() => {
    const analyticsOpts = {
      step: 0,
      dataType: getStringMode(mode),
    };
    analyticsService.uploadStepViewed(analyticsOpts);
  }, [mode]);

  const resetState = useCallback(() => {
    setState((s) => {
      const step = 0;
      const analyticsOpts = {
        step,
        dataType: getStringMode(mode),
      };
      analyticsService.uploadStepViewed(analyticsOpts);
      return {
        ...s,
        error: '',
        loading: false,
        step,
      };
    });
  }, [setState, mode]);

  const onUploadContinueWithPaste = (data: Blob, isSample = false) =>
    onUploadContinue(data, isSample ? 'SAMPLE_FILE' : 'COPY_PASTE');

  const onUploadContinue = async (data: File | Blob, uploadType: UploadTypeEnum = 'FILE') => {
    setState((s) => ({
      ...s,
      uploadType,
      loading: true,
      error: '',
    }));

    const dataType = getStringMode(mode);

    try {
      switch (mode) {
        case DataUploaderMode.NewNavs: {
          await navsOnUploadContinue(data, uploadType);
          break;
        }
        case DataUploaderMode.Privates: {
          await privatesOnUploadContinue(data);
          break;
        }
        default: {
          await returnOnUploadContinue(data, uploadType);
          break;
        }
      }
    } catch (e) {
      const error = e?.message;
      const code = e?.code;
      const message = [10423, 10424, 10421, 10428, 10425, 10434].includes(code)
        ? error
        : 'Unable to load data. Double-check your format and try again.';
      analyticsService.uploadStepFailed({
        dataType,
        step: state.step,
        error,
      });
      setState((s) => ({
        ...s,
        loading: false,
        error: message,
      }));
    }
  };

  const onMappingChange = async (newMapping: FileMapping) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: '',
    }));
    try {
      const { mapping } = await fetchUpdateMapping(newMapping.fileId, newMapping);
      const { errors } = await fetchCorrectData(newMapping.fileId, []);
      setState((s) => ({
        ...s,
        loading: false,
        errors,
        mapping,
      }));
    } catch (e) {
      const error = e?.message || 'An error occurred updating mapping';
      analyticsService.uploadStepFailed({
        dataType: getStringMode(mode),
        step: state.step,
        error,
      });
      setState((s) => ({
        ...s,
        loading: false,
        error,
      }));
    }
  };

  const onNavMapping = (mapping: FileMapping) => {
    setState((s) => ({
      ...s,
      mapping,
    }));
  };

  const onReviewContinue = async (mapping: FileMapping, corrections: ErrorViewModel[]) => {
    const { mapping: { fileId } = mapping, uploadType, metadata } = state;
    setState((s) => ({
      ...s,
      loading: true,
      error: '',
    }));
    try {
      await fetchCorrectData(fileId, corrections);
      const { errorCount, createdCount, updatedCount, responseObject } = await fetchPersistSeries(fileId, uploadType!);
      setState((s) => ({
        ...s,
        loading: false,
      }));
      const analyticsObj = {
        dataType: getStringMode(mode),
        numberOfDiscarded: errorCount,
        numberOfExisting: updatedCount,
        numberOfInvestments: updatedCount + createdCount,
        numberOfNew: createdCount,
        uploadType: uploadType ? ANALYTICS_UPLOAD_TYPE[uploadType] : undefined,
      };

      analyticsService.investmentsUploaded(analyticsObj);
      if (onComplete) {
        const { updatedPortfolio, newFunds } = portfolio
          ? addInvestmentsToPortfolio(responseObject, portfolio, strategyId)
          : {
              updatedPortfolio: createPortfolioFromFunds(responseObject),
              newFunds: undefined,
            };
        onComplete(updatedPortfolio, newFunds);
      }
      if (onCompleteNavigate && metadata) {
        onCompleteNavigate(
          mode,
          responseObject.map((fund) => fund.id),
        );
      }
      setUserCompletedUpload(true);
    } catch (e) {
      const error = e?.message || 'Unknown error in review step';
      analyticsService.uploadStepFailed({
        dataType: getStringMode(mode),
        step: state.step,
        error,
      });
      setState((s) => ({
        ...s,
        loading: false,
        error,
      }));
    }
  };

  const onPrivatesReviewContinue = async (parsedPrivateFunds: ParsedPrivateFunds) => {
    setState((s) => ({
      ...s,
      loading: true,
      error: '',
    }));
    try {
      // updated mappings
      const newMappings = parsedPrivateFunds.mappings.map((m) => {
        let writeType = m.writeType;
        switch (fundAppendTypes[m.rawFundName] ?? globalAppendType) {
          case AppendType.OVERWRITE_ALL:
            writeType = 'OVERWRITE';
            break;
          case AppendType.OVERWRITE_OVERLAPPING:
            writeType = 'UPSERT';
            break;
          case AppendType.APPEND:
            writeType = 'APPEND';
            break;
          default:
            logExceptionIntoSentry(`Unknown append type: ${fundAppendTypes[m.rawFundName] ?? globalAppendType}`);
        }
        return { ...m, writeType };
      });

      const result = await storePrivatesXLS(parsedPrivateFunds.fileId, newMappings);
      setState((s) => ({
        ...s,
        loading: false,
      }));

      if (onCompleteNavigate && parsedPrivateFunds.mappings) {
        onCompleteNavigate(
          mode,
          result.content.map((fund) => fund.id),
        );
      }
      setUserCompletedUpload(true);
    } catch (e) {
      const error = e?.content?.message || 'Unknown error in review step';
      analyticsService.uploadStepFailed({
        dataType: getStringMode(mode),
        step: state.step,
        error,
      });
      setState((s) => ({
        ...s,
        loading: false,
        error,
      }));
    }
  };

  const onNavMappingContinue = (mapping: FileMapping) => {
    if (!mapping || !mapping.columns) {
      return;
    }
    const mappedFunds = mapping.columns.map((column) => ({
      portfolioNodeId: column.portfolioNodeId,
      id: column.fundId,
      name: column.fundName,
      allocation: column.newNav,
    }));
    const { updatedPortfolio, newFunds } = portfolio
      ? addInvestmentsToPortfolio(mappedFunds, portfolio, strategyId)
      : {
          updatedPortfolio: createPortfolioFromFunds(mappedFunds),
          newFunds: undefined,
        };
    if (onComplete) {
      onComplete(updatedPortfolio, newFunds);
    }
  };

  const resetToInitial = useCallback(() => {
    resetState();
    // reverting to the initial mode in case we are running V2 experience (available only with privates)
    // otherwise just resetting local state is sufficient
    if (userHasPrivates) {
      setMode(DataUploaderMode.Initial);
    }
  }, [setMode, resetState, userHasPrivates]);
  const renderStep = () => {
    const { step, parsedPrivateFunds, mapping, metadata, errors, loading, uploadType } = state;
    const view = steps[step];
    switch (view) {
      case DataUploaderView.Initial:
        return <SelectDataType onCancel={onCancel} onSelectMode={setMode} />;
      case DataUploaderView.Upload:
        return (
          <Upload
            onCancel={onCancel}
            mode={mode}
            setMode={setMode}
            onPaste={onUploadContinueWithPaste}
            onUpload={onUploadContinue}
            loading={loading}
          />
        );
      case DataUploaderView.Review:
        if (mode === DataUploaderMode.Privates) {
          if (!parsedPrivateFunds || !metadata) {
            return null;
          }
          return (
            <ReviewPrivates
              parsedPrivateFunds={parsedPrivateFunds}
              onMappingChange={onMappingChange}
              onCancel={onCancel}
              onStartOver={resetToInitial}
              onContinue={onPrivatesReviewContinue}
              loading={loading}
              customUploadReviewButtonLabel={customUploadReviewButtonLabel}
              errors={[]}
              metadata={metadata}
            />
          );
        }
        if (!mapping || !metadata) {
          return null;
        }
        return (
          <Review
            metadata={metadata}
            mapping={mapping}
            errorColumns={errors}
            onMappingChange={onMappingChange}
            onCancel={onCancel}
            onStartOver={resetToInitial}
            onContinue={onReviewContinue}
            loading={loading}
            isSample={uploadType === 'SAMPLE_FILE'}
            customUploadReviewButtonLabel={customUploadReviewButtonLabel}
          />
        );
      case DataUploaderView.DisplayNavMapping:
        if (!mapping || !metadata) {
          // something went wrong if mapping hasn't been set at this point, so start over
          resetState();
          break;
        }
        return (
          <MappingNavs
            mode={mode}
            portfolio={portfolio}
            strategyId={strategyId}
            metadata={metadata}
            mapping={mapping}
            onCancel={onCancel}
            onMappingChange={onNavMapping}
            onContinue={onNavMappingContinue}
            onStartOver={resetState}
          />
        );
    }
    return null;
  };

  const processFileMapping = async (mapping: FileMapping, uploadType: UploadTypeEnum) => {
    const { columns, fileId } = mapping;
    if (columns.length === 0) {
      const error = 'There is no valid data to upload. Double-check your format and try again.';
      analyticsService.uploadStepFailed({
        dataType: getStringMode(mode),
        step: state.step,
        error,
      });
      setState((s) => ({
        ...s,
        loading: false,
        error,
      }));
      return;
    }

    const metadata = await cachedGetFileUploadMetadata();
    const { errors } = await fetchCorrectData(fileId, []);
    trackColumnErrors(errors, state.step + 1);
    goToNextStep({
      mapping,
      metadata: metadata.content,
      errors,
      uploadType,
    });
  };

  const returnOnUploadContinue = async (data: File | Blob, uploadType: UploadTypeEnum) => {
    // Set the uploaded file to "draft" status so that it does not appear in the app. The file will
    // be marked as non-draft when the extracted returns are complete.
    const document = await fetchUploadFile(data, uploadType, true);
    const { mapping } = await fetchParsedFile(document);

    await processFileMapping(mapping, uploadType);
  };

  const privatesOnUploadContinue = async (data: File | Blob) => {
    // Set the uploaded file to "draft" status so that it does not appear in the app. The file will
    // be marked as non-draft when the extracted returns are complete.
    const parsedPrivateFunds = await fetchPrivatesUploadFile(data);
    const metadata = await cachedGetFileUploadMetadata();

    goToNextStep({
      metadata: metadata.content,
      parsedPrivateFunds,
    });
  };

  const navsOnUploadContinue = async (data: File | Blob, uploadType: UploadTypeEnum) => {
    // there should always be a portfolio when uploading navs
    if (!portfolio) {
      return;
    }
    const file = await fetchNewNavsUploadFile(data, uploadType);
    const mapping = getMapping(file, portfolio, strategyId);
    const metadata = await cachedGetFileUploadMetadata();
    goToNextStep({
      mapping,
      metadata: metadata.content,
      uploadType,
    });
  };

  const goToNextStep = (stateUpdates: Partial<DataUploaderState>) => {
    const uploadType = state.uploadType || stateUpdates.uploadType;
    analyticsService.uploadStepCompleted({
      step: state.step,
      dataType: getStringMode(mode),
      uploadType: uploadType ? String(uploadType) : undefined,
    });

    const nextStep = state.step + 1;
    setState((s) => ({
      ...s,
      ...stateUpdates,
      loading: false,
      step: nextStep,
    }));

    analyticsService.uploadStepViewed({
      step: nextStep,
      dataType: getStringMode(mode),
      uploadType,
    });
  };

  return (
    <Provider
      value={{
        mode,
        error: state.error,
        step: state.step,
        setStep: (step: number) =>
          setState((s) => ({
            ...s,
            step,
          })),
        portfolio,
      }}
    >
      <StyledModal testId="uploadModal" closeOnEsc onClose={onCancel} zIndex={ZIndex.Modal}>
        <div>
          {renderStep()}
          {state.loading && steps[state.step] !== DataUploaderView.Upload && <Loading />}
        </div>
      </StyledModal>
    </Provider>
  );
};

export default DataUploader;

const StyledModal = styled(Modal)`
  &.modal-wrapper {
    width: 1000px;
    height: 685px;
    max-height: 685px;
    border-radius: 4px;
    padding: 0;

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
`;
