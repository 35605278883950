import React, { useCallback, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Headline3, Notifications, NotificationType, SIDE_NAV_WIDTH, TOP_NAV_HEIGHT } from 'venn-ui-kit';
import { Modal, ModalSize } from 'venn-components';
import { requestVennFeatureInfo } from 'venn-api';
import {
  UpsellAccessFeatures,
  UpsellBackButton,
  UpsellContactFormContainer,
  UpsellDescriptionSpan,
  UpsellFeature,
  UpsellFooter,
  UpsellFooterText,
  UpsellFurtherContactButton,
  UpsellIconWithMargin,
  UpsellLeftContainer,
  UpsellMainContainer,
  UpsellMainTextContainer,
  UpsellSecondPageContainer,
  UpsellSecondPageLeftContainer,
  UpsellStyledButton,
  UpsellStyledInput,
  UpsellTopContainer,
  type UpsellFeatureProps,
  type UpsellProps,
} from './UpsellFlyoutShared';

const FIRST_PAGE_INDEX = 0;
const SECOND_PAGE_INDEX = 1;

const featuresConfig: UpsellFeatureProps[] = [
  {
    title: 'Templates',
    description:
      'Choose from our library or create templates powered by Venn metrics, ' +
      'graphics, and analytics to communicate clearly and effectively with any audience.',
  },
  {
    title: 'Branding',
    description: 'Firm-specific branding, logos, images, and color palettes can be customized to all reports',
  },
  {
    title: 'Exports',
    description: 'Once created, reports can also be readily exported as a PDF.',
  },
];

export const ReportlabUpsell = ({ onClose }: UpsellProps) => {
  const { Images } = useContext(ThemeContext);
  const [page, setPage] = React.useState(FIRST_PAGE_INDEX);
  const [additionalText, setAdditionalText] = React.useState('');

  const onAdditionalTextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setAdditionalText(e.target.value);
    },
    [setAdditionalText],
  );

  const onContactUs = useCallback(async () => {
    setPage(SECOND_PAGE_INDEX);
    await requestVennFeatureInfo({
      featureName: 'Report Lab',
      message: '',
    });
    Notifications.notify('You have successfully submitted a request for more information.', NotificationType.SUCCESS);
  }, [setPage]);

  const onAdditionalContact = useCallback(async () => {
    await requestVennFeatureInfo({
      featureName: 'Report Lab',
      message: additionalText,
    });
    onClose();
    Notifications.notify('You have successfully submitted a request for more information.', NotificationType.SUCCESS);
  }, [additionalText, onClose]);

  return (
    <UpsellStyledModal closeOnEsc closeOnOutsideClick size={ModalSize.Large} onClose={onClose} noPadding>
      {page === 0 && (
        <UpsellMainContainer>
          <UpsellTopContainer>
            <UpsellLeftContainer>
              <Headline3>Introducing Venn's Report Lab</Headline3>
              <UpsellMainTextContainer>
                Report Lab enables the creation of client-ready, customizable, branded reports, using the power of Two
                Sigma’s Venn analytics and data visualization library. Quickly construct reports and proposals, craft
                your narrative, and present with confidence.
              </UpsellMainTextContainer>
              {featuresConfig.map((props) => (
                <UpsellFeature key={props.title} {...props} />
              ))}
            </UpsellLeftContainer>
            <UpsellStyledImg
              key={Images.reportlabSlideoutAsset}
              src={Images.reportlabSlideoutAsset}
              alt="reportlab in-app demo"
            />
          </UpsellTopContainer>
          <UpsellFooter>
            <UpsellFooterText>Interested in learning more? Submit a request and we’ll be in touch.</UpsellFooterText>
            <UpsellStyledButton dense onClick={onContactUs} className="portal-menu-ignore-activity">
              Submit Request
            </UpsellStyledButton>
          </UpsellFooter>
        </UpsellMainContainer>
      )}
      {page === 1 && (
        <UpsellSecondPageContainer>
          <UpsellSecondPageLeftContainer>
            <UpsellBackButton dense onClick={() => setPage(FIRST_PAGE_INDEX)} className="portal-menu-ignore-activity">
              <UpsellIconWithMargin type="arrow-left" />
              Back
            </UpsellBackButton>
            <UpsellSecondPageImg
              key={Images.reportlabSlideoutAsset2}
              src={Images.reportlabSlideoutAsset2}
              alt="reportlab second in-app demo"
            />
            <UpsellAccessFeatures>With access to the following features and more!</UpsellAccessFeatures>
            {featuresConfig.map((props) => (
              <UpsellFeature key={props.title} title={props.title} description="" />
            ))}
          </UpsellSecondPageLeftContainer>
          <UpsellContactFormContainer>
            <Headline3>Thanks for getting in touch.</Headline3>
            <UpsellDescriptionSpan>
              <b>Is there anything else you would like us to know? (optional)</b>
            </UpsellDescriptionSpan>
            <UpsellStyledInput
              placeholder="Enter here"
              defaultValue=""
              onChange={onAdditionalTextChange}
              minLength={1}
              maxLength={500}
            />
            <UpsellFurtherContactButton dense onClick={onAdditionalContact} className="portal-menu-ignore-activity">
              Send
            </UpsellFurtherContactButton>
          </UpsellContactFormContainer>
        </UpsellSecondPageContainer>
      )}
    </UpsellStyledModal>
  );
};

const UpsellStyledModal = styled(Modal)`
  &.qa-modal-overlay {
    top: ${TOP_NAV_HEIGHT}px;
    left: ${SIDE_NAV_WIDTH}px;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 65px;
  }
  &.modal-wrapper {
    width: 1088px;
    height: 576px;
  }

  @media print {
    display: none;
  }
`;

const UpsellStyledImg = styled.img`
  width: 568px;
  height: 436px;
`;

const UpsellSecondPageImg = styled.img`
  background-color: white;
  width: 405px;
  height: 322px;
  margin-bottom: 16px;
  margin-top: 41px;
`;
