import React, { useCallback, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Modal, ModalSize } from 'venn-components';
import { requestVennFeatureInfo, type AnalysisView } from 'venn-api';
import {
  analyticsService,
  LibraryTab,
  navigateToLibrary,
  navigateToStudioView,
  ReportingStyleEnum,
  useHasFF,
  type StudioTemplateState,
} from 'venn-utils';
import { useHistory } from 'react-router-dom';
import {
  UpsellAccessFeatures,
  UpsellBackButton,
  UpsellContactFormContainer,
  UpsellDescriptionSpan,
  UpsellFeature,
  UpsellFooter,
  UpsellFooterText,
  UpsellFurtherContactButton,
  UpsellIconWithMargin,
  UpsellLeftContainer,
  UpsellMainContainer,
  UpsellMainTextContainer,
  UpsellRightContainer,
  UpsellSecondPageContainer,
  UpsellSecondPageLeftContainer,
  UpsellStyledButton,
  UpsellStyledInput,
  UpsellStyledUnlockedButton,
  UpsellStyledUnlockedPrimaryButton,
  UpsellTopContainer,
  type UpsellFeatureProps,
  type UpsellProps,
} from './UpsellFlyoutShared';
import { GetColor, Headline3, Notifications, NotificationType, SIDE_NAV_WIDTH, TOP_NAV_HEIGHT } from 'venn-ui-kit';

const PRIVATES_DEMO_TEMPLATE_ID = '2c85b7e7-c2e0-4455-a280-3e0bf4911b04';
const FIRST_PAGE_INDEX = 0;
const SECOND_PAGE_INDEX = 1;

const featuresConfig: UpsellFeatureProps[] = [
  {
    title: '18,000+ funds',
    description: 'Access metadata, fund performance characteristics, and cash flows in our Private Asset Data Library.',
  },
  {
    title: 'Desmoothing, Interpolation, and Extrapolation',
    description:
      'View private asset returns through a more public lens and analyze a multi-asset portfolio more holistically.',
  },
  {
    title: 'Performance Metrics',
    description: 'Visualize IRR and PMEs, both at the investment and aggregated portfolio levels.',
  },
  {
    title: 'Cash Flow Modeling',
    description:
      'Manage funding and capital call default risks with the ability to visualize historical and projected cash flows.',
  },
  {
    title: 'Total Portfolio Asset Growth Simulation',
    description: 'Estimate the probability of a funding shortfall and project a portfolio’s asset allocation.',
  },
];

export const PALUpsell = ({ onClose }: UpsellProps) => {
  const history = useHistory();
  const { Images } = useContext(ThemeContext);
  const [page, setPage] = React.useState(FIRST_PAGE_INDEX);
  const [additionalText, setAdditionalText] = React.useState('');
  const hasPrivatesFF = useHasFF('private_analytics');

  const onAdditionalTextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setAdditionalText(e.target.value);
    },
    [setAdditionalText],
  );

  const onContactUs = useCallback(async () => {
    setPage(SECOND_PAGE_INDEX);
    await requestVennFeatureInfo({
      featureName: 'Private Asset Lab',
      message: '',
    });
    Notifications.notify('You have successfully submitted a request for more information.', NotificationType.SUCCESS);
  }, [setPage]);

  const onAdditionalContact = useCallback(async () => {
    await requestVennFeatureInfo({
      featureName: 'Private Asset Lab',
      message: additionalText,
    });
    onClose();
    Notifications.notify('You have successfully submitted a request for more information.', NotificationType.SUCCESS);
  }, [additionalText, onClose]);

  const onTryInStudio = useCallback(() => {
    analyticsService.creatingNewStudios({
      source: 'PAL upsell slideout menu',
      name: 'PAL Template',
    });

    const document: StudioTemplateState = {
      id: PRIVATES_DEMO_TEMPLATE_ID,
      newDocument: {
        id: PRIVATES_DEMO_TEMPLATE_ID,
        analysisViewType: ReportingStyleEnum.TEARSHEET,
      } as AnalysisView,
    };
    onClose();

    navigateToStudioView(history, document);
  }, [onClose, history]);

  const onOpenPrivatesLibrary = useCallback(() => {
    analyticsService.ctaClicked({
      destination: 'Privates Library page',
      locationOnPage: 'PAL Upsell Flyout',
      purpose: 'pal upsell navigation to library',
      type: 'button',
    });

    onClose();
    navigateToLibrary(history, {
      tab: LibraryTab.PrivateAssets,
    });
  }, [onClose, history]);

  return (
    <UpsellStyledModal closeOnEsc closeOnOutsideClick size={ModalSize.Large} onClose={onClose} noPadding>
      {page === 0 && (
        <UpsellMainContainer>
          <UpsellTopContainer>
            <UpsellLeftContainer>
              <HeadlineContainer>
                <Headline3>Introducing Venn's Private Asset Lab</Headline3>
                <BetaTag>BETA</BetaTag>
              </HeadlineContainer>
              <UpsellMainTextContainer>
                Private Asset Lab is a specialized solution to help analyze your illiquid and less transparent private
                asset classes.
              </UpsellMainTextContainer>
              {featuresConfig.map((props) => (
                <UpsellFeature key={props.title} {...props} />
              ))}
            </UpsellLeftContainer>
            <UpsellRightContainer>
              <UpsellStyledImg
                key={Images.privateAssetLabSlideoutAsset}
                src={Images.privateAssetLabSlideoutAsset}
                alt="pal in-app demo"
              />
            </UpsellRightContainer>
          </UpsellTopContainer>
          {!hasPrivatesFF && (
            <UpsellFooter>
              <UpsellFooterText>Interested in learning more? Submit a request and we’ll be in touch.</UpsellFooterText>
              <UpsellStyledButton dense onClick={onContactUs} className="portal-menu-ignore-activity">
                Submit request
              </UpsellStyledButton>
            </UpsellFooter>
          )}
          {hasPrivatesFF && (
            <UpsellFooter>
              <UpsellFooterText>Get started</UpsellFooterText>
              <UpsellStyledUnlockedButton
                dense
                onClick={onOpenPrivatesLibrary}
                noMargin
                className="portal-menu-ignore-activity"
              >
                Browse the private asset data library
              </UpsellStyledUnlockedButton>
              <UpsellStyledUnlockedPrimaryButton
                dense
                onClick={onTryInStudio}
                noMargin
                className="portal-menu-ignore-activity"
              >
                Try it in studio
              </UpsellStyledUnlockedPrimaryButton>
            </UpsellFooter>
          )}
        </UpsellMainContainer>
      )}
      {page === 1 && (
        <UpsellSecondPageContainer>
          <UpsellSecondPageLeftContainer>
            <UpsellBackButton dense onClick={() => setPage(FIRST_PAGE_INDEX)} className="portal-menu-ignore-activity">
              <UpsellIconWithMargin type="arrow-left" />
              Back
            </UpsellBackButton>
            <UpsellSecondPageImg
              key={Images.privateAssetLabSlideoutAsset2}
              src={Images.privateAssetLabSlideoutAsset2}
              alt="pal second in-app demo"
            />
            <UpsellAccessFeatures>With access to the following features and more!</UpsellAccessFeatures>
            {featuresConfig.map((props) => (
              <UpsellFeature key={props.title} title={props.title} description="" />
            ))}
          </UpsellSecondPageLeftContainer>
          <UpsellContactFormContainer>
            <Headline3>Thanks for getting in touch.</Headline3>
            <UpsellDescriptionSpan>
              <b>Is there anything else you would like us to know? (optional)</b>
            </UpsellDescriptionSpan>
            <UpsellStyledInput
              placeholder="Enter here"
              defaultValue=""
              onChange={onAdditionalTextChange}
              minLength={1}
              maxLength={500}
            />
            <UpsellFurtherContactButton dense onClick={onAdditionalContact} className="portal-menu-ignore-activity">
              Send
            </UpsellFurtherContactButton>
          </UpsellContactFormContainer>
        </UpsellSecondPageContainer>
      )}
    </UpsellStyledModal>
  );
};

const UpsellStyledModal = styled(Modal)`
  &.qa-modal-overlay {
    top: ${TOP_NAV_HEIGHT}px;
    left: ${SIDE_NAV_WIDTH}px;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 128px;
  }
  &.modal-wrapper {
    width: 1088px;
    height: 596px;
  }

  @media print {
    display: none;
  }
`;

const UpsellStyledImg = styled.img`
  width: 546px;
  height: 330px;
`;

const UpsellSecondPageImg = styled.img`
  background-color: white;
  width: 390px;
  height: 236px;
  margin-bottom: 16px;
  margin-top: 41px;
`;

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const BetaTag = styled.span`
  padding: 1px 4px;
  border-radius: 4px;
  background: ${GetColor.HighlightDark};
  height: 14px;
  font-size: 9px;
  font-weight: 700;
  color: ${GetColor.White};
  display: flex;
  justify-content: center;
  align-items: center;
`;
